import type { IndustryOptionsObject } from "../../translation-keys/industry-option-keys.js";

const FormIndustryOptions: IndustryOptionsObject = {
  "Active Pharmaceutical Ingredients": "활성 제약 성분",
  "Aerospace": "항공우주",
  "Animal Health": "동물 건강",
  "Automotive": "자동차",
  "Banking/Finance/Insurance": "은행/금융/보험",
  "Biotechnology - Med Device": "생명공학 - 의료기기",
  "Biotechnology - Pharma": "생명공학 - 제약",
  "Blood & Biologics": "혈액 및 생물학",
  "Chemicals": "화학",
  "Clinical Research": "임상 연구",
  "Combination Device": "결합 장치",
  "Combination Pharma": "복합 제약",
  "Communications": "통신",
  "Computer Equipment/Peripherals": "컴퓨터 장비/주변기기",
  "Construction/Architecture/Engineering": "건설/건축/엔지니어링",
  "Consulting Services": "컨설팅 서비스",
  "Contract Manufacturing - Med Device": "계약 제조 - 의료기기",
  "Contract Manufacturing - Pharma": "계약 제조업체 - 제약",
  "Contract Research Organization (CRO)": "계약 연구 기관(CRO)",
  "Cosmetics": "화장품",
  "Diagnostics - Medical": "진단 - 의료",
  "Dietary Supplements": "건강 보조 식품",
  "Distribution": "유통",
  "Education": "교육",
  "Electronics": "전자제품",
  "Energy": "에너지",
  "Environmental": "환경",
  "Food": "식품",
  "General Manufacturing": "일반 제조",
  "Government": "정부",
  "Healthcare": "보건 의료",
  "Hospital": "병원",
  "Laboratory - Biologics": "연구소 - 생물학",
  "Laboratory - ISO": "연구소 - ISO",
  "Laboratory - MD": "연구소 - MD",
  "Laboratory - Other": "연구소 - 기타",
  "Lawyer / Law Firm": "변호사/로펌",
  "Life Science Supplier": "생명과학 공급업체",
  "Media": "언론",
  "Medical Device": "의료기기",
  "Medical Marijuana": "의료용 마리화나",
  "Nuclear": "원자력",
  "Oil & Gas / Mining": "석유 및 가스 / 광업",
  "Packaging": "포장",
  "Pharmaceutical": "제약",
  "Publishing": "출판",
  "Retail": "소매",
  "Software/Hardware Supplier": "소프트웨어/하드웨어 공급업체",
  "Student/University": "학생/대학",
  "Technology": "기술",
  "Transportation/Logistics": "운송/물류",
  "Travel/Hospitality/Restaurant": "여행/접객/레스토랑"
};

export default FormIndustryOptions;